import { isHavingValue, isNullOrUndefined, notNullNotUndefined } from "./objectUtils";
import { isWindowDefined } from "./windowUtils";

export const isEmpty = (value) => {
    if (value === undefined) {
        return true;
    }
    if (value === null) {
        return true;
    }
    if (value.length === 0) {
        return true;
    }
    return false;
};

export const isNotEmpty = (value) => {
    return isEmpty(value) === false;
};

export const notEmpty = (value) => {
    if (isEmpty(value)) {
        throw Error('value cannot be empty. see isEmpty()');
    }
};

export const whenIsEmptyThen = (value, fallback) => {
    if (isEmpty(value)) {
        return fallback;
    }
    return value;
};

export const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
}
  

export const isStringEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

export const isUUID = ( uuid ) => {
    let s = "" + uuid;

    s = s.match('^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$');
    if (s === null) {
      return false;
    }
    return true;
}

export const isString = (val) => {
    return typeof val === 'string' || val instanceof String
}

export const isNotString = (val) => {
    return !isString(val)
}

export const makeid = (length) => {
    var result           = '';
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
      result += characters.charAt(Math.floor(Math.random() * 
 charactersLength));
   }
   return result;
}

export const encodeBase64 = (str) => {
    if (isWindowDefined()) {
        //window
        return window.btoa(str)
    }

    //node js
    return Buffer.from(str, 'utf8').toString('base64')
}

export const camelCaseToHumanFriendly = (val, capitalizeOnlyFirst) => {
    if (isNullOrUndefined(val)) {
        return null
    }

    if (val.length === 0) {
        return ''
    }

    var words = val.match(/[A-Za-z][a-z]*|[0-9]+/g) || []

    if (capitalizeOnlyFirst) {
       if (words.length > 0) {
            return words.map((w, idx)=>{
                if (idx === 0) {
                    return capitalize(w)
                }
                else {
                    return lowercase(w)
                }
            }).join(" ");
       }
    }

    return words.map(capitalize).join(" ");

}

export const capitalize = (word) => {
    return word.charAt(0).toUpperCase() + word.substring(1);
}

export const kebabCaseToHumanFriendly = (str) => {
    notNullNotUndefined(str)
    if (str.length === 0) {
        return str
    }
    var re = new RegExp('-', 'g');
    return capitalize(str.replace(re, ' '))
}

export const toKebabCase = string => string
    .replace(/([a-z])([A-Z])/g, '$1-$2')
    .replace(/[\s_]+/g, '-')
    .toLowerCase()

export const toSnakeCase = str => str && str
    .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
    .map(x => x.toLowerCase())
    .join('_')

function lowercase(word) {
    return word.charAt(0).toLowerCase() + word.substring(1);
}

export const substrMaxlength = (str, length) => {
    notNullNotUndefined(str)
    notNullNotUndefined(length)
    if (str.length <= length) {
        return str
    }
    return str.substr(0,length)+'...'
}

export const stringToColor = (string) => {
    notNullNotUndefined(string)

    let hash = 0;
    let i;
  
    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }
  
    let color = '#';
  
    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */
  
    return color;
}

export const addLeading = (str, char) => {
    notNullNotUndefined(str)
    notNullNotUndefined(char)

    if (str.startsWith(char)) {
        return str
    } else {
        return char+str
    }
} 
  
export const removeLeading = (str, char) => {
    notNullNotUndefined(str)
    notNullNotUndefined(char)

    if (str.startsWith(char)) {
       return str.substring(char.length, str.length)
    }

    return str;
}

export const removeTrailing = (str, substr) => {
    notNullNotUndefined(str)
    notNullNotUndefined(substr)

    if (str.endsWith(substr)) {
       return str.substring(0, str.length-substr.length)
    }

    return str;
}

/* export const strEndsWith = (str, suffix) => {
    return str.match(suffix+"$")==suffix;
} */

export const stringToInitials = (name) => {
    notNullNotUndefined(name)
    const nameParts = name.split(' ')
    const firstPart = nameParts[0]
    const secondPart = nameParts.length > 1 ? nameParts[1] : null

    if (!secondPart) {
        return `${firstPart[0]}`
    }

    return `${firstPart[0]}${secondPart[0]}`
}

export const stringToHslColor = (str, s, l) =>{
    notNullNotUndefined(str)

    var hash = 0;
    for (var i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
  
    var h = (hash % 15) + 345; //range: 345-360
    return 'hsl('+h+', '+s+'%, '+l+'%)';
}

export const blurString = (val) => {
    if (!isHavingValue(val)) {
        return val
    }

    const parts = val.split(' ')

    let name = ''

    parts.forEach(p => {
        if (p.length > 2) {
            const pVisible = p.slice(0, 2)
            const pBlurred = 'x'.repeat(p.length-2)
            name += pVisible+pBlurred+' '
        }
    })

    return name
}

export const replaceUrlsWithHtmlAnchor = (str) => {
    if (str === null) {
        return null;
    }
    if (!str) {
        return ''
    }
    var exp_match = /(\b(https?|):\/\/[-\u00C0-\u017FA-Z0-9+&@#\/%?=~_|!:,.;]*[-\u00C0-\u017FA-Z0-9+&@#\/%=~_|])/ig;
    var element_content=str.replace(exp_match, "<a href='$1' target='_blank' rel='noreferrer'>$1</a>");
    var new_exp_match =/(^|[^\/])(www\.[\S]+(\b|$))/gim;
    return element_content.replace(new_exp_match, '$1<a target="_blank" href="http://$2" rel="noreferrer">$2</a>');
}

export const replaceUrlsWithHtmlAnchorAndText = (str, text) => {
    if (str === null) {
        return null;
    }
    if (!str) {
        return ''
    }
    var exp_match = /(\b(https?|):\/\/[-\u00C0-\u017FA-Z0-9+&@#\/%?=~_|!:,.;]*[-\u00C0-\u017FA-Z0-9+&@#\/%=~_|])/ig;
    var element_content=str.replace(exp_match, "<a href='$1' target='_blank' rel='noreferrer'>"+text+"</a>");
    var new_exp_match =/(^|[^\/])(www\.[\S]+(\b|$))/gim;
    return element_content.replace(new_exp_match, '$1<a target="_blank" href="http://$2" rel="noreferrer">'+text+'</a>');
}

export const escapeStringJson = (str) => {
    if (str === null) {
        return null
    }
    return str.replace(/\\n/g, "\\n")
                .replace(/\\'/g, "\\'")
                .replace(/\\"/g, '\\"')
                .replace(/\\&/g, "\\&")
                .replace(/\\r/g, "\\r")
                .replace(/\\t/g, "\\t")
                .replace(/\\b/g, "\\b")
                .replace(/\\f/g, "\\f")
}

export const escapeFilename = (str) => {
    notNullNotUndefined(str)
    return str.replace(/[^a-z0-9]/gi, '_').substring(0, 50)
}

export const getFirstMatch = (str, regex) => {
    notNullNotUndefined(str)
    notNullNotUndefined(regex)
    const match = str.match(regex)
   
    if (!match) {
        return null
    }
    return match[1]
}

export const removeHtmlTags = (str) => {
    notNullNotUndefined(str)
    return str.replace(/<\/?[^>]+(>|$)/g, '')
}

export const wordCount = (str) => {
    notNullNotUndefined(str)
    return str.split(' ').filter((n)=>n != '').length;
}

export default { isEmpty, isNotEmpty, notEmpty, whenIsEmptyThen };